/*
 * @Author: dingguowei
 * @Date: 2023-05-18 11:26:59
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-06 17:15:55
 */
import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router/index.js'

var CancelToken = axios.CancelToken

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 3600000 // request timeout
})

// request interceptor 请求之前拦截判断，权限等处理
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (!localStorage.getItem('token')) {
      router.replace({
        name: 'loginPage'
      })
    } else {
      config.headers['x-auth-token'] = localStorage.getItem('token')
    }
    config.cancelToken = new CancelToken(e => {
      store.state.axiosCancel.push(e)
    })
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['X-Token'] = getToken()
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data
    if(res.code == 10000) {
      return res
    }
    if (!res.data) {
      if (!res.code) { 
        return res
      }
      else if (res.code !== 1000) {
        Message({
              message: res.msg,
              type: 'error',
              duration: 2 * 1000
            })
        return Promise.reject(new Error('数据异常！'))
      } else { 
        return res
      }
    } else if (res.data instanceof Array) {
      // if (res.data.length === 0) {
      //   return Promise.reject(new Error('数据异常！'))
      // } else {
        return res
      // }
    } else {
      return res
    }
    // var str = JSON.stringify(res)
    // var json = JSON.parse(str)
    // console.log(json)

    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 20000 && res.code !== 0) {
    //   // Message({
    //   //   message: res.message || 'Error',
    //   //   type: 'error',
    //   //   duration: 5 * 1000
    //   // })

    //   // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     // to re-login
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       store.dispatch('user/resetToken').then(() => {
    //         location.reload()
    //       })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //  }
  },
  (error) => {
    if (error.response.status === 401) {
       localStorage.removeItem('token')
    }
  //   if (error.response.status === 404) {
  //       Message({
  //     message: '未请求到服务器',
  //     type: 'error',
  //     duration: 2 * 1000
  //   })
  //  }
    console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error).catch(err => {
      console.log(err)
    })
    // return Promise.reject(error)
  }
)

export default service
